@tailwind utilities;
@layer utilities {
  /* -------- input element utilities -------- */
  .input-wrap {
    display: block;
    margin-bottom: 1.25rem;
  }
  .input-control,
  .input-append,
  .input-textarea:focus{
    outline: none;
    border: 1px solid var(--purple600);
  }
  .input-control,
  .input-append,
  .input-textarea {
    height: 40px;
    width: 100%;
    display: block;
    border: 1px solid var(--grey300);
    border-radius: 8px;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    box-sizing: border-box;
    margin-top: 0.5rem;
  }

  .input-control[type="checkbox"] {
    height: 18px;
    width: 18px;
    border: 1px solid var(--grey300);
    background: var(--white100);
    accent-color: var(--purple600);
  }

  .input-textarea {
    height: 100px;
    resize: none;
  }

  .input-append {
    display: flex;
    align-items: center;
    gap: 0.275rem;
    padding-right: 0.275rem;
  }

  .input-append .input-control {
    border: none;
    height: inherit;
    height: 35px;
    outline: none;
    margin-top: 0;
    border-left: 1px solid var(--grey300);
    border-radius: 0;
  }

  .input-control[type="radio"] {
    height: 15px;
    width: 15px;
    accent-color: var(--purple600);
  }

  .input-append.no--border .input-control {
    border: none;
  }

  .input-control::placeholder {
    color: var(--grey500);
  }

  label.required::after {
    content: "*";
    color: var(--grey700);
  }

  /* -------- button element utilities ------- */
  .btn {
    min-height: 39px;
    display: block;
    border-radius: 8px;
    border: none;
  }

  .btn:disabled {
    opacity: 0.5;
  }

  .btn-tran {
    @apply bg-white-100 flex items-center gap-2 text-grey-700 h-[fit-content] px-4 border border-solid border-grey-300;
  }

  .btn-error {
    @apply bg-red-50 flex items-center gap-2 h-[fit-content] px-4 text-red-700;
  }
}

/* ------- menu utility classes ------ */
.menu-link {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0.5rem 0.8rem;
}

.menu-link svg path {
  stroke: var(--grey500);
}

.menu-link span {
  color: var(--grey700);
}

.menu-active {
  display: flex;
  gap: 16px;
  align-items: center;
  background: var(--purple50);
  padding: 0.5rem 0.8rem;
  border-radius: 5px;
}

.menu-active svg path {
  stroke: var(--purple500);
}

.menu-active span {
  color: var(--purple700);
}

/* ------ table utility classes ------- */
.table-wrapper {
  width: 100%;
  overflow-x: auto;
  border: 1px solid var(--grey200);
  border-radius: 8px;
}

.table-wrapper table {
  border-collapse: collapse;
  border-spacing: 0px;
  width: 100%;
}

.table-wrapper table thead tr {
  border-bottom: 1px solid var(--grey200);
  display: table-row;
}

.table-wrapper table thead tr th {
  padding: 0.5rem 0.5rem;
  color: var(--grey500);
  font-weight: 400;
  text-align: left;
}

.table-wrapper table thead tr th input,
.table-wrapper table tbody tr td input {
  height: 15px;
  width: 15px;
  accent-color: var(--purple700);
  border-radius: 5px;
}

.table-wrapper table thead tr th svg path {
  stroke: var(--grey500);
}

.table-wrapper tbody tr td {
  padding: 0.8rem 0.5rem;
  font-size: 0.875rem;
  overflow: hidden;
  white-space: nowrap;
}

.table-wrapper tbody tr:nth-child(odd) {
  background-color: var(--grey50);
}

.products-wrap {
  display: flex;
}

.products-wrap > div {
  border: 3px solid var(--white100);
  border: 3px solid var(--white100);
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.products-wrap > div:not(:first-child) {
  margin-left: -1rem;
}

/* ------- image gallery overwrite ------ */
.image-gallery-slide,
.image-gallery-thumbnail-inner {
  border: 1px solid var(--grey920);
  border-radius: 8px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail.active:hover {
  border: none !important;
}

/* ------ switch toggle helper ----- */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--grey400);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--purple600);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--purple600);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.border__sm{
  border:1.1px solid #F1F1F1;
  border-radius: 10px;
}
.border__lg{
  border-radius: 0;
  border: none;
}


.border__sm__img{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.border__lg__img{
  border-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
 display: none;
}

.line-height-lg{
  line-height: 82px !important;
}
.wait-list-input{
    height: 50px;
    width: 100%;
    display: block;
    border: 1px solid #667085;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    box-sizing: border-box;
    background-color: transparent;
}
.wait-list-input:focus{
  outline: none;
  border: 1px solid var(--purple700);
}
.btn-prepend{
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.logo__border{
border-bottom: 1px solid #EAECF0;
border-top: 1px solid #EAECF0;
}
.logo__border__none{
  border: 0;
}

.first__line::after,.second__line::after{
  content: '\a';
  white-space:pre; 
}

@media screen and (max-width:768px) {
  .wait-list-input{
    border-radius: 8px;
  }
  .btn-prepend{
    border-radius: 8px;
  }
  .second__line::after{
    content: ' ';
  }
}

.filter-icon{
  border: 1px solid #D0D5DD;
box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
border-radius: 8px;
}

.border__sm__mobile{
  border:1.1px solid #F1F1F1;
  border-radius: 5px;
}

.round__search__input{
  height: 40px;
  width: 100%;
  display: block;
  border: 1px solid var(--grey300);
  border-radius: 28px;
  box-sizing: border-box;
  padding-left: .6rem;
 
 
}
.normal__link{
  color: #2C2E2F;
}
.search__input{
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.bg-video{
  background: linear-gradient(90deg, #000000 0%, #000000 100%);
  opacity: 0.7;
}
.chart-container {
  position: relative;
  margin: auto;
  height: 80vh;
  width: 80vw;
}

::-webkit-scrollbar {
  width: 10px;
  
   }

   ::-webkit-scrollbar-track{
    background: #8ea3be;
   }

::-webkit-scrollbar-thumb{
  background: #526e92;
  border-radius:15px;
}



