:root {
  /* ------ purple color code ------ */
  --purple600: #7f56d9;
  --purple700: #6941c6;
  --purple300: #d6bbfb;
  --purple100: #f4ebff;
  --purple500: #9e77ed;
  --purple50: #f9f5ff;

  /* ------- grey color codes ------ */
  --grey700: #344054;
  --grey300: #d0d5dd;
  --grey500: #667085;
  --grey600: #475467;
  --grey200: #eaecf0;
  --grey100: #4f4f4f;
  --grey800: #c8c8c8;
  --grey900: #101828;
  --grey50: #f9fafb;
  --grey400: #f2f4f7;
  --grey910: #b9b9b9;
  --grey920: #e9e9e9;
  --grey930: #e0e0e0;

  /* ------- white color codes ------ */
  --white100: #ffffff;
  --white50: #f9f5ff;
  --white200: #f1f1f1;
  --white300: #f7f7f7;
  --white400: #f2f3f4;

  /* ------- black color codes ----- */
  --black100: #333333;

  /* ------ green color codes ------- */
  --green700: #027a48;
  --green50: #ecfdf3;
  --green500: #12b76a;
  --green600: #039855;

  /* ------- red color codes ------- */
  --red700: #b42318;
  --red600: #d92d20;
  --red50: #fef3f2;
}
